<template>
    <div>
        <page-header
            show-close
            @onClose="returnToRoot"
        />
        <error-view
            v-show="error"
            image-path-in-assets="error-icon.svg"
        />
        <loading-indicator
            v-show="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <div v-if="!error && !loading">
            <div
                class="mx-3"
                data-testid="close-account-prompt"
            >
                <h5>
                    {{ $t('pages.closeAccount.prompt.text') }}
                </h5>
            </div>
            <div
                class="d-flex flex-column align-content-stretch flex-wrap mx-3"
                data-testid="close-account-prompt-close-reasons"
            >
                <div
                    v-for="(reason, index) in closeAccountReasons"
                    :key="reason.key"
                    class="border rounded px-3 py-2 mt-2"
                    :data-testid="`close-account-prompt-close-reason-${index}`"
                    style="text-align: center; cursor: pointer"
                    @click="handleSelectCloseAccountReason(reason.value)"
                    @keydown="handleSelectCloseAccountReason(reason.value)"
                >
                    <span class="align-self-center">
                        <b>{{ reason.label }}</b>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import { logger } from '@/utils/logger'
    import { RouteNames } from '@/routes/router'
    import { kebabCase } from 'lodash'
    import { mapActions, mapGetters } from 'vuex'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import ErrorView from '@/components/ErrorView'
    import { AccountClosureReason, AvenAccountStanding } from '@/store'

    const CLOSE_ACCOUNT_REASON_SELLING_MY_HOME = 'Selling My Home'
    const CLOSE_ACCOUNT_REASON_REFINANCING_MY_HOME = 'Refinancing My Home'
    const CLOSE_ACCOUNT_REASON_CASH_OUT_REFI = 'Cash Out Refi'
    const CLOSE_ACCOUNT_REASON_DO_NOT_USE_IT = 'Don’t Use It'
    const CLOSE_ACCOUNT_REASON_NOT_HAPPY_WITH_AVEN = 'Not Happy With Aven'

    export default {
        name: 'CloseAccountPrompt',
        mixins: [navigation],
        components: {
            PageHeader,
            LoadingIndicator,
            ErrorView,
        },
        async mounted() {
            if (this.isAccountPendingCloseOrPayoff) {
                await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
                return
            }
            this.$logEvent('view_close_account_prompt')
        },
        computed: {
            ...mapGetters({
                loading: 'loading',
                error: 'error',
                accountStanding: 'avenAccountStanding',
                isLimitedMode: 'isLimitedMode',
            }),
            isAccountPendingCloseOrPayoff: function () {
                return this.accountStanding === AvenAccountStanding.pendingClose || this.accountStanding === AvenAccountStanding.pendingPayoffThenLimitedMode
            },
            closeAccountReasons: function () {
                return !this.isLimitedMode
                    ? [
                        {
                            key: kebabCase(CLOSE_ACCOUNT_REASON_REFINANCING_MY_HOME),
                            label: CLOSE_ACCOUNT_REASON_REFINANCING_MY_HOME,
                            value: AccountClosureReason.REFI,
                        },
                        {
                            key: kebabCase(CLOSE_ACCOUNT_REASON_CASH_OUT_REFI),
                            label: CLOSE_ACCOUNT_REASON_CASH_OUT_REFI,
                            value: AccountClosureReason.CASH_OUT_REFI,
                        },
                        {
                            key: kebabCase(CLOSE_ACCOUNT_REASON_SELLING_MY_HOME),
                            label: CLOSE_ACCOUNT_REASON_SELLING_MY_HOME,
                            value: AccountClosureReason.SELLING_HOME,
                        },
                        {
                            key: kebabCase(CLOSE_ACCOUNT_REASON_DO_NOT_USE_IT),
                            label: CLOSE_ACCOUNT_REASON_DO_NOT_USE_IT,
                            value: AccountClosureReason.NOT_USE,
                        },
                        {
                            key: kebabCase(CLOSE_ACCOUNT_REASON_NOT_HAPPY_WITH_AVEN),
                            label: CLOSE_ACCOUNT_REASON_NOT_HAPPY_WITH_AVEN,
                            value: AccountClosureReason.NOT_HAPPY,
                        },
                    ]
                    : [
                        {
                            key: kebabCase(CLOSE_ACCOUNT_REASON_DO_NOT_USE_IT),
                            label: CLOSE_ACCOUNT_REASON_DO_NOT_USE_IT,
                            value: AccountClosureReason.NOT_USE,
                        },
                        {
                            key: kebabCase(CLOSE_ACCOUNT_REASON_NOT_HAPPY_WITH_AVEN),
                            label: CLOSE_ACCOUNT_REASON_NOT_HAPPY_WITH_AVEN,
                            value: AccountClosureReason.NOT_HAPPY,
                        },
                    ]
            },
        },
        watch: {
            isAccountPendingCloseOrPayoff: async function (newValue, oldValue) {
                if (newValue) {
                    logger.info(`isAccountPendingCloseOrPayoff changed from ${oldValue} -> ${newValue}`)
                    await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_PAYOFF })
                    return
                }
            },
        },
        methods: {
            ...mapActions(['selectCloseAccountReason', 'updateCloseAccountKeepCard']),
            handleSelectCloseAccountReason: async function (reason) {
                logger.info(`Close Account Reason is ${reason}`)
                this.$logEvent('click_close_account_reason', { reason })
                this.selectCloseAccountReason(reason)
                if (!this.isLimitedMode && (reason === AccountClosureReason.REFI || reason === AccountClosureReason.CASH_OUT_REFI || reason === AccountClosureReason.SELLING_HOME)) {
                    await this.$router.push({ name: RouteNames.CLOSE_ACCOUNT_KEEP_CARD })
                } else {
                    this.updateCloseAccountKeepCard(false)
                    await this.$router.push({ name: RouteNames.CLOSE_ACCOUNT_PERMANENT })
                }
            },
        },
    }
</script>
